import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getConfirmChartData,
  getConfirmLogChartData
} from "../../store/action/analyze.data.action";
import { Divider } from "antd";
import RenderLine from "./RenderLine";

export class GlobalChartContainer extends Component {
  componentDidMount() {
    this.props.getConfirmChartData();
    this.props.getConfirmLogChartData();
  }
  render() {
    return (
      <div>
        {!this.props.confirm_chart_data_pending && (
          <RenderLine
            data={this.props.confirm_chart_data}
            ylabel=""
            xlabel=""
            format={true}
            title="1. Worldwide cumulative cases - confirmed and death"
            colors={["red", "green", "blue"]}
          ></RenderLine>
        )}
        <Divider></Divider>
        {!this.props.confirm_log_chart_data_pending && (
          <RenderLine
            data={this.props.confirm_log_chart_data}
            ylabel=""
            xlabel=""
            max={20}
            min={4}
            title="2. Worldwide confirmed cases (log scale) "
            colors = {["blue"]}
          ></RenderLine>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  confirm_chart_data: state.analyzeDataReducers.confirm_chart_data,
  confirm_chart_data_pending:
    state.analyzeDataReducers.confirm_chart_data_pending,
  confirm_log_chart_data: state.analyzeDataReducers.confirm_log_chart_data,
  confirm_log_chart_data_pending:
    state.analyzeDataReducers.confirm_log_chart_data_pending
});

const mapDispatchToProps = {
  getConfirmChartData,
  getConfirmLogChartData
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalChartContainer);
