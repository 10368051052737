import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Skeleton } from "antd";
import {
  getConfirmChartDataBD,
  getConfirmLogChartBDData,
} from "../../store/action/analyze.data.action";
import RenderLine from "./RenderLine";

export class BDChartContainer extends Component {
  componentDidMount() {
    this.props.getConfirmChartDataBD("Bangladesh");
    this.props.getConfirmLogChartBDData("Bangladesh");
  }
  render() {
    return (
      <>
        {this.props.confirm_chart_data_pending && <Skeleton></Skeleton>}
        {!this.props.confirm_chart_data_pending && (
          <RenderLine
            data={this.props.confirm_chart_data}
            ylabel=""
            xlabel=""
            format={true}
            title="1. Cumulative cases of Bangladesh - confirmed and death"
            colors={["red", "green", "blue"]}
          ></RenderLine>
        )}
        <Divider></Divider>

        {this.props.confirm_log_chart_data_pending && <Skeleton></Skeleton>}
        {!this.props.confirm_log_chart_data_pending && (
          <RenderLine
            data={this.props.confirm_log_chart_data}
            ylabel=""
            xlabel=""
            max={20}
            min={0}
            title="2. Confirmed cases of Bangladesh (log scale) "
            colors={["blue"]}
          ></RenderLine>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  confirm_chart_data: state.analyzeDataReducers.confirm_chart_data_bd,
  confirm_chart_data_pending:
    state.analyzeDataReducers.confirm_chart_data_bd_pending,
  confirm_log_chart_data: state.analyzeDataReducers.confirm_log_chart_data_bd,
  confirm_log_chart_data_pending:
    state.analyzeDataReducers.confirm_log_chart_data_bd_pending,
});

const mapDispatchToProps = {
  getConfirmChartDataBD,
  getConfirmLogChartBDData,
};

export default connect(mapStateToProps, mapDispatchToProps)(BDChartContainer);
