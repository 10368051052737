import React, { Component } from "react";
import { connect } from "react-redux";
import { Skeleton } from "antd";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  Map,
  TileLayer,
  FeatureGroup,
  CircleMarker,
  LayersControl,
} from "react-leaflet";
import RenderCircleBD from "./RenderCircleBD";
import {
  getBDConfirmed,
  getBDConfirmedCS,
} from "../../../store/action/map.bd.action";
import { circleConfirmedConfig } from "../../../config/config";

class RenderMapBD extends Component {
  constructor(props) {
    super(props);
    props.getBDConfirmed();
  }
  render() {
    const position = [23.68, 90.35];

    return (
      <Map center={position} zoom={7}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {!this.props.mapBDPending && (
          <RenderCircleBD
            fillOpacity={circleConfirmedConfig.fillOpacity}
            fillColor={circleConfirmedConfig.fillColor}
            weight={circleConfirmedConfig.weight}
            map_data={this.props.mapBDData}
          ></RenderCircleBD>
        )}
      </Map>
    );
  }
}

const mapStateToProps = (state) => ({
  mapBDData: state.mapBDReducer.mapBDdata,
  mapBDPending: state.mapBDReducer.mapBDPending,
});

const mapDispatchToProps = {
  getBDConfirmed,
  getBDConfirmedCS,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderMapBD);
