import React, { Component } from "react";
import { connect } from "react-redux";
import { getConfirmTable } from "../../store/action/analyze.data.action";
import { Table, Tag, AutoComplete, Skeleton, Input } from "antd";

const { Search } = Input;
const tableColumns = [
  {
    title: "Country/Region",
    dataIndex: "Country_Region",
    key: "Country_Region",
    render: (t, r, i) => {
      return `${r["Country_Region"]}, ${r["Province_State"]}`;
    },
  },
  {
    title: "Confirmed",
    dataIndex: "Confirmed",
    key: "Confirmed",
  },
];
class RenderTable extends Component {
  componentDidMount() {
    this.props.getConfirmTable();
  }
  handleSearch(value){
    console.log(value)
  }
  render() {
    return (
      <>
        {this.props.confirm_table_data_pending && <Skeleton />}

        {/* <Search
          placeholder="input search text"
          enterButton="Search"
          size="small"
          onSearch={(value) => console.log(value)}
        /> */}
        <br/>
        {!this.props.confirm_table_data_pending && (
          <Table
            columns={tableColumns}
            size="small"
            dataSource={this.props.confirm_table_data}
            bordered
          ></Table>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  confirm_table_data: state.analyzeDataReducers.confirm_table_data,
  confirm_table_data_pending:
    state.analyzeDataReducers.confirm_table_data_pending,
});

const mapDispatchToProps = { getConfirmTable };

export default connect(mapStateToProps, mapDispatchToProps)(RenderTable);
