import {combineReducers} from 'redux';

import analyzeDataReducers from './analyze.data.reducer';
import trendDataReducer from './trends.data.reducer';
import mapBDReducer from './map.bd.reducer';

const reducers = combineReducers({
    analyzeDataReducers: analyzeDataReducers,
    trendDataReducer: trendDataReducer,
    mapBDReducer: mapBDReducer
});

export default reducers;