import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Typography } from "antd";
import ReactGA from "react-ga";
import {
  facebookShareAdvice,
  showFBModal,
  closeFBModal,
} from "../../store/action/map.bd.action";
import { CheckCircleTwoTone, FacebookFilled } from "@ant-design/icons";

const { Title } = Typography;

const motto = `1. Clean your hands often with soap and water, or an alcohol-based hand rub.
2. Maintain a safe distance from anyone and use face mask.
3. Don’t touch your eyes, nose or mouth.
4. Stay home if you feel unwell.
5. Cover your nose and mouth with your bent elbow or a tissue when you cough or sneeze.
6. Follow the directions of your local health authority.
7. Keep in touch with family, friends or colleagues via digital means.`;

export class FacebookShareAdvice extends Component {
  shareFB = () => {
    ReactGA.event({
      category: "ShareFBAdvice",
      action: "Adivice Shared",
    });
    window.FB.ui(
      {
        display: "popup",
        method: "share",
        href: "http://globalhealthanalytics.info/",
        quote: motto,
      },
      function (response) {
        console.log("HHH", response);
      }
    );
    this.props.closeFBModal();
  };
  render() {
    return (
      <div>
        <Button type="primary" onClick={this.props.showFBModal} size="small">
          <FacebookFilled twoToneColor="#52c41a" />
          {/* <FacebookOutlined style={{ color: "#3b5998" }} /> */}
          Share COVID-19 advice
        </Button>
        <Modal
          title={
            <Title level={4}>
              "Join us to share the COVID - 19 advice through
              Facebook"
            </Title>
          }
          width={512}
          visible={this.props.visible}
          onCancel={this.props.closeFBModal}
          footer={[
            <Button type="primary" key="shared" onClick={this.props.closeFBModal}>
              x Cancel
            </Button>,
            <Button type="primary" key="notshared" onClick={this.shareFB}>
              <FacebookFilled />
              Share
            </Button>,
          ]}
        >
          <Row>
            <Col lg={24}>
              <Title level={4}>
                Protect yourself and others around you by knowing the facts and
                taking appropriate precautions. Follow advice provided by your
                local public health agency.
              </Title>
            </Col>
            <Col>
              <div className="fbShareAdvice">
                <p>To prevent the spread of COVID-19:</p>
                <p>
                  1. Clean your hands often with soap and water, or an
                  alcohol-based hand rub.
                </p>
                <p>
                  2. Maintain a safe distance from anyone and use face mask.
                </p>
                <p>3. Don’t touch your eyes, nose or mouth.</p>
                <p>4. Stay home if you feel unwell.</p>
                <p>
                  5. Cover your nose and mouth with your bent elbow or a tissue
                  when you cough or sneeze.
                </p>
                <p>6. Follow the directions of your local health authority.</p>
                <p>
                  7. Keep in touch with family, friends or colleagues via
                  digital means
                </p>
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  visible: state.mapBDReducer.fbModalVisible,
});

const mapDispatchToProps = {
  facebookShareAdvice,
  showFBModal,
  closeFBModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacebookShareAdvice);
