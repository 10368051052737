import React, { Component } from "react";
import { Typography } from "antd";
import { ResponsiveLine } from "@nivo/line";
import * as time from "d3-time";
import { timeFormat } from "d3-time-format";
import { kFormatter } from "../../util/util";

const { Title, Text } = Typography;

class TrendRenderLine extends Component {
  render() {
    console.log(this.props.data);
    return (
      <div style={{ height: "170px" }}>
        <p
          style={{
            marginBottom: "0",
            fontWeight: "800",
            color: "#000",
            width: "100%",
            textAlign: "center",
          }}
        >
          {this.props.title}
        </p>
        <ResponsiveLine
          data={this.props.data}
          margin={{ top: 15, right: 30, bottom: 50, left: 60 }}
          xScale={{ type: "time", format: "%m/%d/%Y" }}
          xFormat="time:%m/%d/%Y"
          yScale={{
            type: "linear",
            min: this.props.min || "auto",
            max: this.props.max || "auto",
            stacked: false,
            reverse: false,
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: "%b",
            tickValues: "every month",
            legend: "",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickValues: 5,
            tickRotation: 0,
            legend: this.props.ylabel,
            legendPosition: "start",
            // format: (value) => (this.props.format ? kFormatter(value) : value),
          }}
          colors={this.props.colors}
          pointSize={2}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabel="y"
          pointLabelYOffset={-12}
          enableSlices="x"
          useMesh={false}
          legends={[
            {
              anchor: "top",
              direction: "column",
              justify: false,
              translateX: 0,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 1,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
}

export default TrendRenderLine;
