import React, { Component, PureComponent } from "react";
import { CircleMarker, Popup } from "react-leaflet";
import { circleRadiusBD } from "../../../util/util";
import RenderLegend from "../RenderLegend";

export default class RenderCircleBD extends Component {
  constructor(props) {
    super(props);
    this.createCircle = this.createCircle.bind(this);
    this.circleClick = this.circleClick.bind(this);

    this.state = {
      circleData: [],
    };
  }

  circleClick(e) {
    this.createCircle(e.target.options.info);
  }

  componentDidMount() {
    this.createCircle();
  }

  createCircle(k) {
    console.log(this.props.map_data);
    var circle = [];
    this.props.map_data.map((d)=>{
      if (d["coord"].length && d['confirmed']) {
        var coord = d.coord.reverse();
        circle.push(
          <CircleMarker
            key={d.name}
            info={d.name}
            center={coord}
            radius={circleRadiusBD(d.confirmed)}
            stroke={true}
            fillOpacity={this.props.fillOpacity}
            fillColor={this.props.fillColor}
            weight={this.props.weight}            
            onclick={this.circleClick}
          >
            <Popup>
              <span className="circlemarker-popup">
                <b>
                  {d.name + ": "}
                </b>
              </span>
              <br />
              <span className="circlemarker-popup">
                {"Confirmed: " + d.confirmed}
              </span>
            </Popup>
          </CircleMarker>
        );
      }
    });
    this.setState({
      circleData: circle,
    });
    return circle;
  }
  render() {
    return (
      <>
        {this.state.circleData}
        <RenderLegend fillColor={this.props.fillColor} circleRadius={circleRadiusBD} grades={[0, 100, 200, 500]}></RenderLegend>
      </>
    );
  }
}
