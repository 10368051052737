const initialState = {
  countryTrendsPending: true,
  countryTrendData: [],
  africaCountryTrendsPending: true,
  africaCountryTrendData: [],
  asiaCountryTrendsPending: true,
  asiaCountryTrendData: [],
  europeCountryTrendsPending: true,
  europeCountryTrendData: [],
  northAmericaCountryTrendsPending: true,
  northAmericaCountryTrendData: [],
  southAmericaCountryTrendsPending: true,
  southAmericaCountryTrendData: [],
  timelineDateList: {},
  timelineData: [],
  timelineMax: 0,
  timelineMin: 0,
  timelineDateListPending: true,
  sliderValue: -1,
  sliderStopCheck: 0,
};

const trendDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "COUNTRY_TREND_FULFILLED":
      return {
        ...state,
        countryTrendData: action.payload.data,
        countryTrendsPending: false,
      };
    case "ASIA_COUNTRY_TREND_FULFILLED":
      return {
        ...state,
        asiaCountryTrendData: action.payload.data,
        asiaCountryTrendsPending: false,
      };
    case "EUROPE_COUNTRY_TREND_FULFILLED":
      return {
        ...state,
        europeCountryTrendData: action.payload.data,
        europeCountryTrendsPending: false,
      };
    case "NORTH_AMERICA_COUNTRY_TREND_FULFILLED":
      return {
        ...state,
        northAmericaCountryTrendData: action.payload.data,
        northAmericaCountryTrendsPending: false,
      };
    case "SOUTH_AMERICA_COUNTRY_TREND_FULFILLED":
      return {
        ...state,
        southAmericaCountryTrendData: action.payload.data,
        southAmericaCountryTrendsPending: false,
      };
    case "AFRICA_COUNTRY_TREND_FULFILLED":
      return {
        ...state,
        africaCountryTrendData: action.payload.data,
        africaCountryTrendsPending: false,
      };
    case "GET_TIMELINE_DATE_FULFILLED":
      var dateAll = action.payload.data.dateAll;
      var sliderStopCheck = Object.keys(dateAll).length;
      return {
        ...state,
        timelineDateList: dateAll,
        timelineData: action.payload.data.dataAll,
        timelineMax: action.payload.data.max,
        timelineMin: action.payload.data.min,
        sliderStopCheck: sliderStopCheck,
        timelineDateListPending: false,
      };
    case "START_SIMULATION":
      if (state.sliderValue === action.payload - 1) {
        return {
          ...state,
          sliderValue: -1,
        };
      }
      return {
        ...state,
        sliderValue: state.sliderValue + 1,
      };
    case "RESTART_SIMULATION":
      return {
        ...state,
        sliderValue: state.sliderValue + 1,
      };
    default:
      return state;
  }
};

export default trendDataReducer;
