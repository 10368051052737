export const circleRadius = (d) => {
    return d > 100000 ? 20 : d > 50000 ? 18 : d > 10000 ? 16 : d > 1000 ? 14 : d >= 1 ? 12 : 10;
};

export const circleRadiusBD = (d) => {
    return d > 500 ? 20 : d > 200 ? 18 : d > 100 ? 16 : 10;
};

export const kFormatter = (value) => {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "k", "m", "b", "t"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat(
                (suffixNum != 0 ?
                    value / Math.pow(1000, suffixNum) :
                    value
                ).toPrecision(precision)
            );
            var dotLessShortValue = (shortValue + "").replace(
                /[^a-zA-Z 0-9]+/g,
                ""
            );
            if (dotLessShortValue.length <= 2) {
                break;
            }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
};