import {
    apiDev
} from '.';

export function getTotalCountries() {
    return async dispatch => {
        return dispatch({
            type: 'GET_TOTAL_COUNTRIES',
            payload: apiDev.get('/get-total-countries/')
        })
    }
}
export function getConfirmedTotal() {
    return async dispatch => {
        return dispatch({
            type: 'GET_CONFIRMED_TOTAL',
            payload: apiDev.get('/get-confirmed-total/')
        })
    }
}
export function getDeathTotal() {
    return async dispatch => {
        return dispatch({
            type: 'GET_DEATH_TOTAL',
            payload: apiDev.get('/get-death-total/')
        })
    }
}
export function getRecoveredTotal() {
    return async dispatch => {
        return dispatch({
            type: 'GET_RECOVERED_TOTAL',
            payload: apiDev.get('/get-recovered-total/')
        })
    }
}

export function getConfirmedBDTotal() {
    return async dispatch => {
        return dispatch({
            type: 'GET_CONFIRMED_BD_TOTAL',
            payload: apiDev.get('/get-bd-total/')
        })
    }
}

export function getMapData() {
    return async dispatch => {
        return dispatch({
            type: 'GET_MAP_DATA',
            payload: apiDev.get('/get-map-data/')
        })
    }
}

export function getConfirmChartData() {
    return async dispatch => {
        return dispatch({
            type: 'GET_CONFIRM_CHART_DATA',
            payload: apiDev.post('/get-chart-confirmed-total-global/')
        })
    }
}

export function getConfirmChartDataBD(country) {
    return async dispatch => {
        return dispatch({
            type: 'GET_CONFIRM_CHART_DATA_BD',
            payload: apiDev.post('/get-chart-confirmed-total-global/', {"country": country})
        })
    }
}

export function getConfirmLogChartData() {
    return async dispatch => {
        return dispatch({
            type: 'GET_CONFIRM_LOG_CHART_DATA',
            payload: apiDev.post('/get-chart-confirmed-log/')
        })
    }
}

export function getConfirmLogChartBDData(country) {
    return async dispatch => {
        return dispatch({
            type: 'GET_CONFIRM_LOG_BD_CHART_DATA',
            payload: apiDev.post('/get-chart-confirmed-log/', {"country": country})
        })
    }
}

export function getConfirmTable() {
    return async dispatch => {
        return dispatch({
            type: 'GET_CONFIRM_TABLE_DATA',
            payload: apiDev.post('/get-table-confirmed/')
        })
    }
}

export function getLastUpdateTime() {
    return async dispatch => {
        return dispatch({
            type: 'GET_LAST_UPDATE_TIME',
            payload: apiDev.post('/get-last-update-time/')
        })
    }
}

export function getUSTotal(country) {
    return async dispatch => {
        return dispatch({
            type: 'GET_US_TOTAL',
            payload: apiDev.post('/get-country-total/', {"country": country})
        })
    }
}

export function getSpainTotal(country) {
    return async dispatch => {
        return dispatch({
            type: 'GET_SPAIN_TOTAL',
            payload: apiDev.post('/get-country-total/', {"country": country})
        })
    }
}

export function getGermanyTotal(country) {
    return async dispatch => {
        return dispatch({
            type: 'GET_GERMANY_TOTAL',
            payload: apiDev.post('/get-country-total/', {"country": country})
        })
    }
}

export function getItalyTotal(country) {
    return async dispatch => {
        return dispatch({
            type: 'GET_ITALY_TOTAL',
            payload: apiDev.post('/get-country-total/', {"country": country})
        })
    }
}

export function getFranceTotal(country) {
    return async dispatch => {
        return dispatch({
            type: 'GET_FRANCE_TOTAL',
            payload: apiDev.post('/get-country-total/', {"country": country})
        })
    }
}

export function getUKTotal(country) {
    return async dispatch => {
        return dispatch({
            type: 'GET_UK_TOTAL',
            payload: apiDev.post('/get-country-total/', {"country": country})
        })
    }
}

export function getIndiaTotal(country) {
    return async dispatch => {
        return dispatch({
            type: 'GET_INDIA_TOTAL',
            payload: apiDev.post('/get-country-total/', {"country": country})
        })
    }
}


export function openModal() {
    return async dispatch => {
        return dispatch({
            type: 'OPEN_MODAL',
            modal_visible: true
        })
    }
}

export function closeModal() {
    return async dispatch => {
        return dispatch({
            type: 'CLOSE_MODAL',
            modal_visible: false
        })
    }
}