import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Typography } from "antd";
import { Map, TileLayer } from "react-leaflet";
import _ from "loadsh";
import {
  getTimelineDate,
  startSimulation,
} from "../../../store/action/trends.data.action";

import MapSlider from "./MapSlider";
import RenderCircle from "./RenderCircle";
import {
  circleConfirmedConfig,
  mapSimulationConfig,
} from "../../../config/config";

const { Text } = Typography;
class MapSimulation extends Component {
  constructor(props) {
    super(props);
    this.startSimulation = this.startSimulation.bind(this);
    this.stopSimulation = this.stopSimulation.bind(this);
    this.pauseSimulation = this.pauseSimulation.bind(this);
    this.interval = 0;
    this.isPause = false;
  }

  startSimulation() {
    if (this.interval === 0 || this.isPause) {
      this.isPause = false;
      this.interval = setInterval(() => {
        this.props.startSimulation(this.props.max);
      }, 500);
    }
  }

  pauseSimulation() {
    clearInterval(this.interval);
    this.isPause = true;
  }

  stopSimulation() {
    clearInterval(this.interval);
  }

  componentDidMount() {
    this.props.getTimelineDate();
  }
  componentDidUpdate() {
    var { max, min, sliderValue } = this.props;
    if (sliderValue === max - 1) {
      clearInterval(this.interval);
      this.interval = 0;
    }
  }
  render() {
    var { dateAll, max, min, data, sliderValue } = this.props;
    var map_data = [];
    _.filter(data, (r) => {
      map_data.push({
        Country_Region: r["Country/Region"],
        Lat: r["Lat"],
        Long: r["Long"],
        Confirmed: r[dateAll[sliderValue]],
      });
    });

    var currentDate = dateAll[sliderValue];
    
    var cDate = new Date(currentDate);
    // cDate = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' }).format(cDate);
    var position = [22.1667, 113.55];
    var location = "China";
    if (cDate <= new Date("02/20/2020")) {
      position = [22.1667, 113.55];
      location = "China";
    } else if (cDate <= new Date("03/01/2020")) {
      position = [24.6376, 20.135171];
      location = "Europe";
    } else if (cDate <= new Date("03/15/2020")) {
      position = [9.102, 18.28];
      location = "Africa";
    } else if (cDate <= new Date("04/01/2020")) {
      position = [37.09027, -95.7129];
      location = "USA";
    } else if (cDate <= new Date("04/20/2020")) {
      position = [-14.23, -51.92];
      location = "South AMERICA";
    } else {
      position = [22.1667, 113.55];
      location = "China";
    }

    return (
      <div>
        {!this.props.timelineDateListPending && (
          <>
            <MapSlider
              value={this.props.sliderValue}
              startSimulation={this.startSimulation}
              pauseSimulation={this.pauseSimulation}
              dateAll={dateAll}
              max={max}
              min={min}
            ></MapSlider>
            <Row style={{ marginTop: "-30px" }}>
              <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                <Text style={{ color: "#000", background: 'rgb(245, 136, 18)' }}>
                  {location && location + ": "} {currentDate}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col lg={24} xs={24}>
                <Map
                  center={position}
                  zoom={mapSimulationConfig.zoom}
                  className="simulatedMap"
                  animate={true}
                >
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {map_data.length && (
                    <RenderCircle
                      currentDate={currentDate}
                      fillOpacity={circleConfirmedConfig.fillOpacity}
                      fillColor={circleConfirmedConfig.fillColor}
                      weight={circleConfirmedConfig.weight}
                      map_data={map_data}
                      renderLengend={true}
                    />
                  )}
                </Map>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dateAll: state.trendDataReducer.timelineDateList,
  timelineDateListPending: state.trendDataReducer.timelineDateListPending,
  data: state.trendDataReducer.timelineData,
  max: state.trendDataReducer.timelineMax,
  min: state.trendDataReducer.timelineMin,
  sliderValue: state.trendDataReducer.sliderValue,
});

const mapDispatchToProps = {
  getTimelineDate,
  startSimulation,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapSimulation);
