import React, { Component } from "react";
import { connect } from "react-redux";
import L from "leaflet";
import {
  Map,
  TileLayer,
  FeatureGroup,
  CircleMarker,
  LayersControl,
} from "react-leaflet";
import RenderCircle from "./RenderCircle";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { getMapData } from "../../store/action/analyze.data.action";
import { circleConfirmedConfig } from "../../config/config";

import { GoogleLayer } from "../../../node_modules/react-leaflet-google-v2";
const { BaseLayer } = LayersControl;
const key = "AIzaSyCZcwdMoLrjHNoTuVbWb_tKPdObWsmrT-s";
const terrain = "TERRAIN";
const road = "ROADMAP";
const satellite = "SATELLITE";
const hydrid = "HYBRID";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

class RenderMap extends Component {
  constructor(props) {
    super(props);
    props.getMapData();
  }

  render() {
    const position = [51.505, -0.09];
    return (
      <Map center={position} zoom={3}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* <LayersControl position="bottomleft">
          <BaseLayer name="OpenStreetMap.Mapnik">
            <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
          </BaseLayer>
          <BaseLayer checked name="Google Maps Roads">
            <GoogleLayer googlekey={key} maptype={road} />
          </BaseLayer>
          <BaseLayer name="Google Maps Terrain">
            <GoogleLayer googlekey={key} maptype={terrain} />
          </BaseLayer>
          <BaseLayer name="Google Maps Satellite">
            <GoogleLayer googlekey={key} maptype={satellite} />
          </BaseLayer>
          <BaseLayer name="Google Maps Hydrid">
            <GoogleLayer
              googlekey={key}
              maptype={hydrid}
              libraries={["geometry", "places"]}
            />
          </BaseLayer>
          <BaseLayer name="Google Maps with Libraries">
            <GoogleLayer
              googlekey={key}
              maptype={hydrid}
              libraries={["geometry", "places"]}
            />
          </BaseLayer>
        </LayersControl> */}
        {!this.props.map_data_pending && (
          <RenderCircle
            fillOpacity={circleConfirmedConfig.fillOpacity}
            fillColor={circleConfirmedConfig.fillColor}
            weight={circleConfirmedConfig.weight}
            map_data={this.props.map_data}
            renderLengend={true}
          />
        )}
      </Map>
    );
  }
}

const mapStateToProps = (state) => ({
  map_data: state.analyzeDataReducers.map_data,
  map_data_pending: state.analyzeDataReducers.map_data_pending,
});

const mapDispatchToProps = {
  getMapData,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderMap);
