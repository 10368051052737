import { apiDev } from ".";

export function getCountryTrend(list) {
  return async (dispatch) => {
    return dispatch({
      type: "COUNTRY_TREND",
      payload: apiDev.post("/get-country-confirmed-trend/", {
        country_list: list,
      }),
    });
  };
}

export function getAsiaCountryTrend(list) {
  return async (dispatch) => {
    return dispatch({
      type: "ASIA_COUNTRY_TREND",
      payload: apiDev.post("/get-country-confirmed-trend/", {
        country_list: list,
      }),
    });
  };
}

export function getEuropeCountryTrend(list) {
  return async (dispatch) => {
    return dispatch({
      type: "EUROPE_COUNTRY_TREND",
      payload: apiDev.post("/get-country-confirmed-trend/", {
        country_list: list,
      }),
    });
  };
}

export function getAfricaCountryTrend(list) {
  return async (dispatch) => {
    return dispatch({
      type: "AFRICA_COUNTRY_TREND",
      payload: apiDev.post("/get-country-confirmed-trend/", {
        country_list: list,
      }),
    });
  };
}

export function getNorthAmericaCountryTrend(list) {
  return async (dispatch) => {
    return dispatch({
      type: "NORTH_AMERICA_COUNTRY_TREND",
      payload: apiDev.post("/get-country-confirmed-trend/", {
        country_list: list,
      }),
    });
  };
}

export function getSouthAmericaCountryTrend(list) {
  return async (dispatch) => {
    return dispatch({
      type: "SOUTH_AMERICA_COUNTRY_TREND",
      payload: apiDev.post("/get-country-confirmed-trend/", {
        country_list: list,
      }),
    });
  };
}

export function getTimelineDate() {
  return async (dispatch) => {
    return dispatch({
      type: "GET_TIMELINE_DATE",
      payload: apiDev.post("/get-timeline-date/"),
    });
  };
}

export function startSimulation(v) {
  return {
    type: "START_SIMULATION",
    payload: v
  };
}

export function restartSimulation(){
  return {
    type: "RESTART_SIMULATION",
  };
}