import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Skeleton } from "antd";
import TrendRenderLine from "./TrendRenderLine";
import RenderLine from "./RenderLine";
import { getNorthAmericaCountryTrend } from "../../store/action/trends.data.action";

export class NorthAmericaTrendChartContainer extends Component {
  componentDidMount() {
    this.props.getNorthAmericaCountryTrend(["Mexico", "Canada","Guatemala", "Cuba" , "Haiti", "Honduras", "Nicaragua", "Panama"]);
  }
  render() {
    return (
      <>
        <Row>
          {this.props.countryTrendsPending && <Skeleton></Skeleton>}
          {!this.props.countryTrendsPending &&
            this.props.countryTrendData.map((item) => {
              return (
                <Col lg={12} xs={24} key={item[0]}>
                  <TrendRenderLine
                    data={item.slice(1, item.length)}
                    format={true}
                    ylabel=""
                    xlabel=""
                    title={item[0]}
                    colors={["red", "green", "blue"]}
                  ></TrendRenderLine>
                </Col>
              );
            })}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  countryTrendData: state.trendDataReducer.northAmericaCountryTrendData,
  countryTrendsPending: state.trendDataReducer.northAmericaCountryTrendsPending,
});

const mapDispatchToProps = {
  getNorthAmericaCountryTrend,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NorthAmericaTrendChartContainer);
