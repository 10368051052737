const initialState = {
  total_countries_pending: true,
  total_countries: [],
  confirmed_total: [],
  confirmed_total_pending: true,
  death_total: [],
  death_total_pending: true,
  recovered_total: [],
  recovered_total_pending: true,
  confirmed_bd_total: 0,
  confirmed_bd_total_pending: true,
  map_data_pending: true,
  map_data: {},
  confirm_chart_data_pending: true,
  confirm_chart_data: {},
  confirm_chart_data_bd:{},
  confirm_chart_data_bd_pending: true,
  confirm_log_chart_data_pending: true,
  confirm_log_chart_data: {},
  confirm_log_chart_data_bd: {},
  confirm_log_chart_data_bd_pending: true,
  confirm_table_data_pending: true,
  confirm_table_data: [],
  last_update_time_pending: true,
  last_update_time: [],
  total_us_pending: true,
  total_us: [],
  total_india_pending: true,
  total_india: [],
  total_spain_pending: true,
  total_spain: [],
  total_germany_pending: true,
  total_germany: [],
  total_italy_pending: true,
  total_italy: [],
  total_france_pending: true,
  total_france: [],
  total_uk_pending: true,
  total_uk: [],
  modal_visible: false
};

const analyzeDataReducers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TOTAL_COUNTRIES_FULFILLED":
      return {
        ...state,
        total_countries: action.payload.data,
          total_countries_pending: false
      };
    case "GET_CONFIRMED_TOTAL_FULFILLED":
      return {
        ...state,
        confirmed_total: action.payload.data,
          confirmed_total_pending: false
      };
    case "GET_DEATH_TOTAL_FULFILLED":
      return {
        ...state,
        death_total: action.payload.data,
          death_total_pending: false
      };
    case "GET_RECOVERED_TOTAL_FULFILLED":
      return {
        ...state,
        recovered_total: action.payload.data,
          recovered_total_pending: false
      };
    case "GET_CONFIRMED_BD_TOTAL_FULFILLED":
      return {
        ...state,
        confirmed_bd_total: action.payload.data,
          confirmed_bd_total_pending: false
      };
    case "GET_MAP_DATA_FULFILLED":
      return {
        ...state,
        map_data: action.payload.data,
          map_data_pending: false
      };
    //CHART
    case "GET_CONFIRM_CHART_DATA_FULFILLED":
      console.log(action.payload.data.data);
      return {
        ...state,
        confirm_chart_data: action.payload.data.data,
          confirm_chart_data_pending: false
      };
    case "GET_CONFIRM_CHART_DATA_BD_FULFILLED":
      return {
        ...state,
        confirm_chart_data_bd: action.payload.data.data,
          confirm_chart_data_bd_pending: false
      };
    case "GET_CONFIRM_LOG_CHART_DATA_FULFILLED":
      return {
        ...state,
        confirm_log_chart_data: action.payload.data.data,
          confirm_log_chart_data_pending: false
      };
    case "GET_CONFIRM_LOG_BD_CHART_DATA_FULFILLED":
      return {
        ...state,
        confirm_log_chart_data_bd: action.payload.data.data,
          confirm_log_chart_data_bd_pending: false
      };
    //TABLE
    case "GET_CONFIRM_TABLE_DATA_FULFILLED":
      return {
        ...state,
        confirm_table_data: action.payload.data.data,
          confirm_table_data_pending: false
      };
    case "GET_LAST_UPDATE_TIME_FULFILLED":
      return {
        ...state,
        last_update_time: action.payload.data,
          last_update_time_pending: false
      };
    case "GET_US_TOTAL_FULFILLED":
      return {
        ...state,
        total_us: action.payload.data,
          total_us_pending: false
      };
    case "GET_INDIA_TOTAL_FULFILLED":
      return {
        ...state,
        total_india: action.payload.data,
          total_india_pending: false
      };
    case "GET_SPAIN_TOTAL_FULFILLED":
      return {
        ...state,
        total_spain: action.payload.data,
          total_spain_pending: false
      };
    case "GET_GERMANY_TOTAL_FULFILLED":
      return {
        ...state,
        total_germany: action.payload.data,
          total_germany_pending: false
      };

    case "GET_ITALY_TOTAL_FULFILLED":
      return {
        ...state,
        total_italy: action.payload.data,
          total_italy_pending: false
      };
    case "GET_FRANCE_TOTAL_FULFILLED":
      return {
        ...state,
        total_france: action.payload.data,
          total_france_pending: false
      };

    case "GET_UK_TOTAL_FULFILLED":
      return {
        ...state,
        total_uk: action.payload.data,
          total_uk_pending: false
      };
    case "OPEN_MODAL":
      return {
        ...state,
        modal_visible: true
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modal_visible: false
      };
    default:
      return state;
  }
};

export default analyzeDataReducers;