import React, { Component, PureComponent } from "react";
import { CircleMarker, Popup } from "react-leaflet";
import { circleRadius } from "../../util/util";
import RenderLegend from "./RenderLegend";

export default class RenderCircle extends Component {
  constructor(props) {
    super(props);
    this.createCircle = this.createCircle.bind(this);
    this.circleClick = this.circleClick.bind(this);

    this.state = {
      circleData: [],
    };
  }

  circleClick(e) {
    this.createCircle(e.target.options.info);
  }

  componentDidMount() {
    this.createCircle();
  }

  createCircle(k) {
    var circle = [];
    for (let [key, value] of Object.entries(
      this.props.map_data["Country_Region"]
    )) {
      if (
        this.props.map_data["Lat"][key] &&
        this.props.map_data["Long_"][key]
      ) {
        circle.push(
          <CircleMarker
            key={key}
            info={key}
            center={[
              this.props.map_data["Lat"][key],
              this.props.map_data["Long_"][key],
            ]}
            radius={circleRadius(this.props.map_data["Confirmed"][key])}
            stroke={true}
            fillOpacity={this.props.fillOpacity}
            fillColor={this.props.fillColor}
            weight={this.props.weight}
            onclick={this.circleClick}
          >
            <Popup>
              <span className="circlemarker-popup">
                <b>
                  {this.props.map_data["Province_State"][key] &&
                    this.props.map_data["Province_State"][key] + ": "}
                  {this.props.map_data["Country_Region"][key]}
                </b>
              </span>
              <br />
              <span className="circlemarker-popup">
                {"Confirmed: " + this.props.map_data["Confirmed"][key]}
              </span>
              <br />
              <span className="circlemarker-popup">
                {"Deaths: " + this.props.map_data["Deaths"][key]}
              </span>
              <br />
              <span className="circlemarker-popup">
                {"Recovered: " + this.props.map_data["Recovered"][key]}
              </span>
              <br />
            </Popup>
          </CircleMarker>
        );
      }
    }
    this.setState({
      circleData: circle,
    });
    return circle;
  }
  render() {
    return (
      <>
        {this.state.circleData}
        {this.props.renderLengend && (
          <RenderLegend
            fillColor={this.props.fillColor}
            circleRadius={circleRadius}
            grades={[0, 1000, 10000, 50000, 100000]}
          ></RenderLegend>
        )}
      </>
    );
  }
}
