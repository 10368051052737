import React, { Component } from "react";
import { Tabs, Button, Row, Col } from "antd";
import RenderMap from "./RenderMap";
import RenderMapBD from "./bangladesh/RenderMapBD";
import MapSimulation from "./MapSimulation/MapSimulation";
import FacebookShareAdvice from "../socialmedia/FacebookShareAdvice";

const { TabPane } = Tabs;

class ContainerMapMain extends Component {
  render() {
    return (
      <>
        <Tabs
          className="map-tab"
          defaultActiveKey="1"
          size="small"
          tabPosition="top"
          animated={true}
          type="card"
          tabBarStyle={{ marginBottom: "0px", fontWeight: "bold" }}
          tabBarExtraContent={<FacebookShareAdvice></FacebookShareAdvice>}
        >
          <TabPane tab="Global" key="1">
            <RenderMap></RenderMap>
          </TabPane>
          <TabPane tab="Bangladesh" key="2">
            <RenderMapBD></RenderMapBD>
          </TabPane>
          <TabPane tab="Map Simulation" key="3">
            <MapSimulation></MapSimulation>
          </TabPane>
        </Tabs>
      </>
    );
  }
}

export default ContainerMapMain;
