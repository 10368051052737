import React, { Component } from "react";
import { Row, Col, Button, Slider, Divider } from "antd";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";

export default class MapSlider extends Component {
  render() {
    const { max, min, dateAll, value } = this.props;
    return (
      <>
        {/* <Divider style={{background: 'rgb(64,64,128)'}}></Divider> */}
        <Row justify="center" gutter={4} style={{ marginTop: "2px" }}>
          <Col lg={4} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlayCircleOutlined />}
              size="default"
              onClick={this.props.startSimulation}
            >
              Play
            </Button>
          </Col>
          <Col lg={16}>
            <Slider
              className="date-slider"
              max={max}
              min={min}
              marks={dateAll}
              value={value || 0}
              tipFormatter={(v) => {
                return dateAll[v];
              }}
            ></Slider>
          </Col>
          <Col lg={4}>
            <Button
              type="primary"
              icon={<PauseCircleOutlined />}
              size="default"
              onClick={this.props.pauseSimulation}
            >
              Stop
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
