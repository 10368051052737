import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import { getConfirmTable } from "../../store/action/analyze.data.action";
import { Table, Tag } from "antd";

const { TabPane } = Tabs;
const confirmedTableColumns = [
  {
    title: "",
    dataIndex: "Country_Region",
    key: "Country_Region",
    render: (t, r, i) => {
      return `${r["Country_Region"]}, ${r["Province_State"]}, ${r["Confirmed"]}`;
    },
  }
];
class CardTab extends Component {
  componentDidMount() {
    this.props.getConfirmTable();
  }
  render() {
    return (
      <Tabs type="card">
        <TabPane tab="Confirmed" key="11">
          {!this.props.confirm_table_data_pending && (
            <Table
              scroll={{ y: "calc(100vh - 80vh)" }}
              columns={confirmedTableColumns}
              dataSource={this.props.confirm_table_data}
              bordered
              pagination={{ pageSize: 30}}
            ></Table>
          )}
        </TabPane>
        <TabPane tab="Deaths" key="12"></TabPane>
        <TabPane tab="Recovered" key="13"></TabPane>
        <TabPane tab="Active" key="14"></TabPane>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => ({
  confirm_table_data: state.analyzeDataReducers.confirm_table_data,
  confirm_table_data_pending:
    state.analyzeDataReducers.confirm_table_data_pending,
});

const mapDispatchToProps = { getConfirmTable };

export default connect(mapStateToProps, mapDispatchToProps)(CardTab);
