import { apiDev } from ".";
import ReactGA from "react-ga";

export function getBDConfirmed() {
  return async (dispatch) => {
    return dispatch({
      type: "BD_MAP",
      payload: apiDev.post("/get-bd-confirmed/"),
    });
  };
}

export function getBDConfirmedCS() {
  return async (dispatch) => {
    return dispatch({
      type: "BD_MAP_CS",
      payload: apiDev.post("/get-bd-confirmed-cs/"),
    });
  };
}

export function facebookShareAdvice() {
  return async (dispatch) => {
    return dispatch({
      type: "FACEBOOK_SHARE_ADVICE",
      payload: apiDev.post("/get-bd-confirmed-cs/"),
    });
  };
}

export function showFBModal() {
  return {
    type: "SHOW_FACEBOOK_MODAL",
  };
}

export function closeFBModal() {
  ReactGA.event({
    category: "CancelShareFBAdviceCancel",
    action: "Cancel Adivice Shared",
  });
  return {
    type: "CLOSE_FACEBOOK_MODAL",
  };
}
