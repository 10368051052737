import React, { Component } from "react";
import { Row, Col, Typography, Divider, Tabs } from "antd";
import { blue, red, green } from "@ant-design/colors";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageHeader from "../../main/PageHeader";
import { config } from "../../../config/config";

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const cImageList = [
  {
    source: "/static/analytics/daily_increase.PNG",
    title: "Daily Cases (worldwide)",
  },
  {
    source: "/static/analytics/50_countries_graph.png",
    title: "Covid burden in most affected countries",
  },
  {
    source: "/static/analytics/flattened_curve.png",
    title: "Daily confirmed new cases in the most affected 9 countries",
    notes:
      "Note: Flattening the curve involves reducing the number of new COVID-19 cases from one day to the next.",
  },
  {
    source: "/static/analytics/top_10_countries.png",
    title: "COVID-19 Outbreaks",
  },
  {
    source: "/static/analytics/mortality.png",
    title: "Mortality in the most affected countries",
  },
  {
    source: "/static/analytics/covid_intensity.PNG",
    title: "How COVID-19 Compares to Past Outbreaks",
  },
  {
    source: "/static/analytics/10_days_model.png",
    title: "Mortality in the most affected countries",
    notes: (
      <>
        <Text>Some key assumptions about COVID-19 go like this:</Text>
        <br />
        <Text>
          • The number of people having died from COVID-19 is much more reliable
          than the number of infections.
        </Text>
        <br />
        <Text>
          • Our best estimate of the true fatality rate of COVID-19 still is
          0.7% of the number of infected persons and
        </Text>
        <br />
        <Text>
          • We know that the time from reporting of an infection to death is
          about 10 days.
        </Text>
        <br />
        <Text>
          With this knowledge, we can infer the people that got actually
          infected 10 days ago and deduce the percentage of confirmed vs.
          actually infected persons for the two most affected countries so far:
        </Text>
      </>
    ),
    reference: (
      <>
        <Text>Reference</Text>
        <br />
        <a
          rel="noopener noreferrer"
          href="https://blog.ephorie.de/"
          target="_blank"
        >
          https://blog.ephorie.de/
        </a>
        <br />
        <Text>
          <a
            rel="noopener noreferrer"
            href="https://www.newscientist.com/article/mg24532733-700-why-is-it-so-hard-to-calculate-how-many-people-will-die-from-covid-19/"
            target="_blank"
          >
            {" "}
            https://www.newscientist.com/article/mg24532733-700-why-is-it-so-hard-to-calculate-how-many-people-will-die-from-covid-19/
          </a>
        </Text>
      </>
    ),
  },
  {
    source: "/static/analytics/growth_factor_six_countries.PNG",
    title: "Growth factor, rolling 5-day average",
  },
  {
    source: "/static/analytics/Apple_mobility_30_Apr.PNG",
    title: "Human mobility trends since February, 2020 in selected countries",
  },
  {
    source: "/static/analytics/world_heat_map.PNG",
    title: "World heat map",
  },
];

const populationImageList = [
  {
    source: "/static/analytics/population/pop_growth_rate_world.PNG",
    title: "Population growth rate in world",
  },
  {
    source: "/static/analytics/population/pop_doubling_times_years.PNG",
    title: "Population doubling time in the world",
  },
  {
    source: "/static/analytics/population/pop_growth_by_income_group.PNG",
    title: "Population growth rates in countries (Population per million)",
  },
  {
    source: "/static/analytics/population/pop_growth_rate_by_income_group.PNG",
    title: "Population growth rates in countries (%)",
  },
];
class Analytics extends Component {
  render() {
    return (
      <>
        <Row justify="end">
          <Col lg={16} xs={16} style={{ textAlign: "right" }}>
            <Text>Last Updated: {config.analyticsUpdateDate}</Text>
          </Col>
        </Row>
        <Tabs
          className="map-tab tab-center analytics-tab"
          defaultActiveKey="1"
          type="card"
          size="large"
          animated={true}
          style={{ marginBottom: 32 }}
        >
          <TabPane tab="COVID-19 analytics" key="1">
            {cImageList.map((item, index) => (
              <Row justify="center" key={index}>
                <Col lg={24} xs={24}>
                  <Text style={{ fontSize: "18px", color: blue.primary }}>
                    {index + 1 + "."} {item.title}
                  </Text>
                  <br />
                  <br />
                  {item.notes && (
                    <div style={{ marginBottom: "10px" }}>
                      <Text style={{ fontStyle: "italic" }}>{item.notes}</Text>
                    </div>
                  )}
                  <img
                    className="analytics-img"
                    src={item.source}
                    alt={item.title}
                  />
                  <br />
                  {item.reference && (
                    <div style={{ fontStyle: "italic" }}>{item.reference}</div>
                  )}
                </Col>
                <Divider></Divider>
              </Row>
            ))}
          </TabPane>
          <TabPane tab="World population dynamics" key="2">
            {populationImageList.map((item, index) => (
              <Row justify="center" key={index}>
                <Col lg={24} xs={24}>
                  <Text style={{ fontSize: "18px", color: blue.primary }}>
                    {index + 1 + "."} {item.title}
                  </Text>
                  <br />
                  <br />
                  {item.notes && (
                    <div style={{ marginBottom: "10px" }}>
                      <Text style={{ fontStyle: "italic" }}>{item.notes}</Text>
                    </div>
                  )}
                  <img
                    className="analytics-img"
                    src={item.source}
                    alt={item.title}
                  />
                  <br />
                  {item.reference && (
                    <div style={{ fontStyle: "italic" }}>{item.reference}</div>
                  )}
                </Col>
                <Divider></Divider>
              </Row>
            ))}
          </TabPane>
        </Tabs>
      </>
    );
  }
}

export default Analytics;
