import React, { Component, PureComponent } from "react";
import { CircleMarker, Popup } from "react-leaflet";
import { circleRadius } from "../../../util/util";

export default class RenderCircle extends Component {
  constructor(props) {
    super(props);
    this.createCircle = this.createCircle.bind(this);
    this.circleClick = this.circleClick.bind(this);
  }

  circleClick(e) {
    this.createCircle(e.target.options.info);
  }

  componentDidMount() {
      
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    
  }
  createCircle(k) {
    var circle = [];
    this.props.map_data.map((item, index)=>{
        if (
          item["Lat"] &&
          item["Long"] && item["Confirmed"]
        ) {
          circle.push(
            <CircleMarker
              key={index}              
              center={[
                item["Lat"],
                item["Long"],
              ]}
              radius={circleRadius(item["Confirmed"])}
              stroke={true}
              fillOpacity={this.props.fillOpacity}
              fillColor={this.props.fillColor}
              weight={this.props.weight}
              onclick={this.circleClick}
            >
            </CircleMarker>
          );
        }
    });
    return circle;
  }
  render() {
      var circleData = this.createCircle();
    return (
      <>
        {circleData}
      </>
    );
  }
}
