import React, { Component } from "react";
import { MapControl, CircleMarker, withLeaflet } from "react-leaflet";
import L from "leaflet";

class RenderLegend extends MapControl {
  createLeafletElement(props) {}
  componentDidMount() {
    const legend = L.control({ position: "bottomright" });
    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      const grades = this.props.grades;
      var { fillColor } = this.props;
      let labels = [];
      let from;
      let to;
      var cx = 30,
        cy = 30,
        tx = 50,
        ty = 35,
        diff = 40;
      for (let i = 0; i < grades.length; i++) {
        var r = this.props.circleRadius(grades[i]);
        from = grades[i];
        to = grades[i + 1];
        labels.push(
          `<circle cx=${cx} cy=${cy} r=${r} stroke="false" fillOpacity="0.1" fill=${fillColor} />
          <text x=${tx} y=${ty} font-family="sans-serif" font-size="14px" fill="black">${
            from + 1
          } ${to ? "&ndash;" + to : "+"}</text>`
        );
        cy = cy + diff;
        ty = ty + diff;
      }
      div.innerHTML =
        "<h1 style='font-size: 12px; color: black;'>Cumulative Confirmed Cases</h1><svg viewBox='0 0 150 220'>" +
        labels.join() +
        "</svg>";
      return div;
    };

    const { map } = this.props.leaflet;
    legend.addTo(map);
  }
}
export default withLeaflet(RenderLegend);
