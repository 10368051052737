import React, { Component } from "react";
import { Row, Col, Typography, Menu, Layout, Button } from "antd";
import {
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  MenuOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;
const { Header } = Layout;

class PageHeader extends Component {
  render() {
    return (
      <Header
        style={{
          background: "rgb(245, 136, 18)",
          height: "40px",
          color: "#000",
          textAlign: "center",
          fontSize: "35px",
          overflow: "hidden",
        }}
      >
        <Button
          className="btn-custom1"
          style={{ position: "absolute", left: "0", top: "5px" }}
          onClick={this.props.showDrawer}
        >
          <MenuOutlined></MenuOutlined>
          Data
        </Button>
        <Row justify="end" align="center">
          <Col span={16}>
            <Title level={3} style={{ marginTop: "4px" }}>
              COVID-19 Surveillance Dashboard version 1.0
            </Title>
          </Col>
          <Col span={4}>
            <Menu
              className="share-menu"
              mode="horizontal"
              inlineIndent={0}
              style={{
                background: "rgb(245, 136, 18)",
                position: "relative",
                top: "2px",
              }}
            >
              <Menu.Item key="ld">
                <Text
                  style={{
                    color: "#000",
                    // fontWeight: "bold",
                    position: "relative",
                    top: "-2px",
                  }}
                >
                  Share With:{" "}
                </Text>
                <a
                  href="https://www.linkedin.com/shareArticle?mini=true&url=http://globalhealthanalytics.info/&title=http://globalhealthanalytics.info/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <LinkedinOutlined
                    style={{ color: "#1c87bd" }}
                    className="social-icons"
                  />
                </a>
              </Menu.Item>
              <Menu.Item key="fb">
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=http://globalhealthanalytics.info/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FacebookOutlined
                    style={{ color: "#3b5998" }}
                    className="social-icons"
                  />
                </a>
              </Menu.Item>
              <Menu.Item key="tw">
                <a
                  href="https://twitter.com/intent/tweet?url=http://globalhealthanalytics.info/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <TwitterOutlined
                    style={{ color: "#38A1F3" }}
                    className="social-icons"
                  />
                </a>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        {/* Disclaimer: Information contained in this dashboard must not be
          considered for medical guidance or professional advice. The data and
          visualization are presented here are on an “AS IS” and “AS AVAILABLE”
          basis and is intended for educational purposes only. */}
      </Header>
    );
  }
}

export default PageHeader;
