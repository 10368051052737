const config = {
    debugApp: false,
    productionUrl: 'https://globalhealthanalytics-container-service-1.vd6g55abg2p8i.us-east-1.cs.amazonlightsail.com/dashboardapi',
    developmentUrl: 'http://localhost:8000/dashboardapi',
    analyticsUpdateDate: '01 May, 2020',
    headerBg: 'rgb(245, 136, 18)'
};

const circleConfirmedConfig = {
    fillColor: "rgb(255,0,0)",
    fillOpacity: 0.5,
    weight: 1
};

const mapSimulationConfig = {
    zoom: 3,

}
export {
    config,
    circleConfirmedConfig,
    mapSimulationConfig
};