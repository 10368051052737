import React, { Component } from "react";
import { Col, Tabs, Button, Modal } from "antd";
import { DatabaseOutlined } from "@ant-design/icons";
import GlobalChartContainer from "../chart/GlobalChartContainer";
import BDChartContainer from "../chart/BDChartContainer";
import AfricaTrendChartContainer from "../chart/AfricaTrendChartContainer";
import AsiaTrendChartContainer from "../chart/AsiaTrendChartContainer";
import EuropeTrendChartContainer from "../chart/EuropeTrendChartContainer";
import NorthAmericaTrendChartContainer from "../chart/NorthAmericaTrendChartContainer";
import SouthAmericaTrendChartContainer from "../chart/SouthAmericaTrendChartContainer";
import CountryTrendChartContainer from "../chart/CountryTrendChartContainer";
import RenderTable from "../table/RenderTable";

import Analytics from "../page/Analytics/Analytics";
const { TabPane } = Tabs;

class ChartContainer extends Component {
  state = { visible: false, showDrawer: false, adviceModal: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Tabs
          defaultActiveKey="1"
          className="map-tab"
          type="card"
          animated={true}
          style={{ background: "#fff" }}
          tabBarExtraContent={
            <Button className="btn-custom1" onClick={this.showModal}>
              <DatabaseOutlined /> Analytics
            </Button>
          }
        >
          <TabPane tab="Bangladesh" key="1">
            <BDChartContainer></BDChartContainer>
          </TabPane>
          <TabPane tab="Global" key="2">
            <GlobalChartContainer></GlobalChartContainer>
          </TabPane>
          <TabPane tab="Country-specific" key="3">
            <Tabs
              defaultActiveKey="11"
              style={{ background: "#fff" }}
              type="card"
              animated={true}
            >
              <TabPane tab="Top 8 countries" key="11">
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Top 8 countries
                </p>
                <CountryTrendChartContainer></CountryTrendChartContainer>
              </TabPane>
              <TabPane tab="Asia" key="12">
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                ></p>
                <AsiaTrendChartContainer></AsiaTrendChartContainer>
              </TabPane>
              <TabPane tab="Europe" key="13">
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                ></p>
                <EuropeTrendChartContainer></EuropeTrendChartContainer>
              </TabPane>
              <TabPane tab="Africa" key="14">
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                ></p>
                <AfricaTrendChartContainer></AfricaTrendChartContainer>
              </TabPane>
              <TabPane tab="North America" key="15">
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                ></p>
                <NorthAmericaTrendChartContainer></NorthAmericaTrendChartContainer>
              </TabPane>
              <TabPane tab="South America" key="16">
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                ></p>
                <SouthAmericaTrendChartContainer></SouthAmericaTrendChartContainer>
              </TabPane>
            </Tabs>
          </TabPane>
          <TabPane tab="Table" key="5">
            <RenderTable></RenderTable>
          </TabPane>
        </Tabs>

        <Modal
          title="Visual Analytics"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1024}
          className="analytics-modal"
        >
          <Analytics />
        </Modal>
      </>
    );
  }
}

export default ChartContainer;
