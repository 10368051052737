import React, { Component } from "react";
import "antd/dist/antd.css";
import {
  Layout,
  Row,
  Col,
  Tabs,
  Button,
  Modal,
  Typography,
  Drawer,
  Card,
  Anchor,
} from "antd";
import { DatabaseOutlined, CloseOutlined } from "@ant-design/icons";
import ChartContainer from "../chart/ChartContainer";
import PageHeader from "./PageHeader";
import TotalCard from "../sum/TotalCard";
import ContainerMapMain from "../map/ContainerMapMain";

import { config } from "../../config/config";

const { Footer, Content } = Layout;
const { TabPane } = Tabs;
const { Text, Title } = Typography;
const { Link } = Anchor;

const motto = `To prevent the spread of COVID-19: 1. Clean your hands often. Use soap and water, or an alcohol-based hand rub.2. Maintain a safe distance from anyone who is coughing or sneezing. 3. Dont touch your eyes, nose or mouth. 4. Cover your nose and mouth with your bent elbow or a tissue when you cough or sneeze. 5. Stay home if you feel unwell. 6. If you have a fever, a cough, and difficulty breathing, seek medical attention. Call in advance. 7. Follow the directions of your local health authority. 8. Avoid unneeded visits to medical facilities allows healthcare systems to operate more effectively, therefore protecting you and others.`;

class ContainerMain extends Component {
  state = { visible: false, showDrawer: false, adviceModal: false };

  componentDidMount() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "179653119890199",
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: "v2.6", // use version 2.1
      });

      // Now that we've initialized the JavaScript SDK, we call
      // FB.getLoginStatus().  This function gets the state of the
      // person visiting this page and can return one of three states to
      // the callback you provide.  They can be:
      //
      // 1. Logged into your app ('connected')
      // 2. Logged into Facebook, but not your app ('not_authorized')
      // 3. Not logged into Facebook and can't tell if they are logged into
      //    your app or not.
      //
      // These three cases are handled in the callback function.
      // window.FB.getLoginStatus(function (response) {
      //   // this.statusChangeCallback(response);
      //   console.log(response);
      // });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  adviceModal = () => {
    // this.setState({
    //   adviceModal: !this.state.adviceModal,
    // });
    window.FB.ui(
      {
        display: "popup",
        method: "share",
        href: "http://globalhealthanalytics.info/",
        quote: motto,
      },
      function (response) {
        console.log(response);
      }
    );
  };

  showDrawer = () => {
    this.setState({
      showDrawer: !this.state.showDrawer,
    });
  };

  render() {
    return (
      <Layout>
        <PageHeader showDrawer={this.showDrawer}></PageHeader>
        <Drawer
          className="drawer-menu-left"
          headerStyle={{
            background: config.headerBg,
            textAlign: "center",
            fontWeight: "bold",
            height: "40px",
            padding: "5px",
          }}
          bodyStyle={{ padding: "0", overflow: "hidden" }}
          // width={200}
          title={
            <>
              <Title level={4}>
                COVID-19 Surveillance Dashboard{" "}
                <CloseOutlined
                  onClick={this.showDrawer}
                  style={{ color: "white" }}
                />
              </Title>
            </>
          }
          placement="left"
          closable={false}
          onClose={this.showDrawer}
          visible={this.state.showDrawer}
        >
          <Row gutter={[4, 8]} justify="center">
            <Col className="gutter-row" lg={24}>
              <Card
                className="single-card"
                headStyle={{
                  fontWeight: "500",
                  textAlign: "center",
                  background: "#cccccc",
                  color: "black",
                  minHeight: "0px",
                }}
                bodyStyle={{ padding: "0px" }}
                title="Data Download"
                style={{ background: "#fafafa" }}
              >
                <Anchor affix={false}>
                  <Link
                    target="_blank"
                    href="https://github.com/CSSEGISandData/COVID-19"
                    title="Dataset"
                  />
                  <Link
                    target="_blank"
                    href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019"
                    title="WHO Resources"
                  />
                  <Link
                    target="_blank"
                    href="https://covid19.healthdata.org/"
                    title="USA COVID-19 Projections"
                  />
                  <Link
                    target="_blank"
                    href="https://www.iedcr.gov.bd/"
                    title="Bangladesh-IEDCR"
                  />
                  <Link
                    target="_blank"
                    href="https://corona.gov.bd/"
                    title="Bangladesh Covid-19 Info"
                  />
                </Anchor>
              </Card>
            </Col>
          </Row>
        </Drawer>
        <Content className="content-container">
          <Row>
            <Col lg={3} xs={24}>
              <TotalCard></TotalCard>
            </Col>
            <Col lg={12} xs={24}>
              <ContainerMapMain></ContainerMapMain>
            </Col>
            <Col
              lg={9}
              xs={24}
              style={{
                height: "95vh",
                background: "#fff",
                paddingLeft: "3px",
                paddingRight: "3px",
              }}
            >
              <ChartContainer></ChartContainer>
            </Col>
          </Row>
        </Content>

        <Modal
          title="Visual Analytics"
          visible={this.state.adviceModal}
          onOk={this.adviceModal}
          width={1024}
        >
          <div>
            <h1>Your Headline</h1>
            <div className="fb-quotable">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse eget tristique nibh, vel consequat purus. Praesent
              molestie, turpis ut ultrices commodo, felis arcu cursus enim, vel
              porttitor ante quam vel lacus. Quisque at laoreet sapien. Proin
              nec purus dolor. Integer pharetra nec ligula a condimentum. Fusce
              sem orci, porta ut felis id, commodo imperdiet risus.Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Suspendisse eget
              tristique nibh, vel consequat purus. Praesent molestie, turpis ut
              ultrices commodo, felis arcu cursus enim, vel porttitor ante quam
              vel lacus. Quisque at laoreet sapien. Proin nec purus dolor.
              Integer pharetra nec ligula a condimentum. Fusce sem orci, porta
              ut felis id, commodo imperdiet risus.
            </div>
          </div>
          <div
            className="fb-quote"
            data-href="http://globalhealthanalytics.info/"
            data-layout="button"
          ></div>
        </Modal>
        <Footer></Footer>
      </Layout>
    );
  }
}

export default ContainerMain;
