const initialState = {
  mapBDPending: true,
  mapBDdata: [],
  mapBDCSPending: true,
  mapBDCSData: [],
  fbModalVisible: false,
};

const mapBDReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BD_MAP_FULFILLED":
      return {
        ...state,
        mapBDdata: action.payload.data,
        mapBDPending: false,
      };
    case "BD_MAP_CS_FULFILLED":
      return {
        ...state,
        mapBDCSData: action.payload.data,
        mapBDCSPending: false,
      };
    case "FACEBOOK_SHARE_ADVICE_FULFILLED":
      console.log(action.payload);
      return {
        ...state,
      };
    case "SHOW_FACEBOOK_MODAL":
      return {
        ...state,
        fbModalVisible: true,
      };
    case "CLOSE_FACEBOOK_MODAL":
      return {
        ...state,
        fbModalVisible: false,
      };
    default:
      return state;
  }
};

export default mapBDReducer;
