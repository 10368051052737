import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Anchor,
  Modal,
  Button,
  Card,
  Typography,
  Tabs,
  Avatar,
} from "antd";
import { LinkedinOutlined, UserOutlined } from "@ant-design/icons";
import { blue, red, green } from "@ant-design/colors";
import CardTab from "./CardTab";
import SingleCard from "./SingleCard";

import {
  getTotalCountries,
  getConfirmedTotal,
  getDeathTotal,
  getRecoveredTotal,
  getConfirmedBDTotal,
  getLastUpdateTime,
  getUSTotal,
  getIndiaTotal,
  getSpainTotal,
  getGermanyTotal,
  getItalyTotal,
  getFranceTotal,
  getUKTotal,
  openModal,
  closeModal,
} from "../../store/action/analyze.data.action";

const { Link } = Anchor;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

class TotalCard extends Component {
  constructor(props) {
    super(props);
    this.tabList = ["21a", "22b", "23c", "24d", "25e", "26f"];
    this.currentTabTopCountries = 0;
    this.startTabRotation = this.startTabRotation.bind(this);
    props.getTotalCountries();
    props.getConfirmedTotal();
    props.getDeathTotal();
    props.getRecoveredTotal();
    props.getConfirmedBDTotal();
    props.getLastUpdateTime();
    props.getUSTotal("US");
    props.getIndiaTotal("India");

    props.getSpainTotal("Spain");
    props.getGermanyTotal("Germany");
    props.getItalyTotal("Italy");
    props.getFranceTotal("France");
    props.getUKTotal("United Kingdom");
  }

  componentDidMount() {
    console.log(this.tabList);
    this.startTabRotation();
  }

  startTabRotation() {
    // setInterval(()=>{
    //   if(this.currentTabTopCountries < this.tabList.length){
    //     this.currentTabTopCountries = this.currentTabTopCountries + 1;
    //   }
    //   else{
    //     this.currentTabTopCountries = 0;
    //   }
    //   console.log(this.tabList[this.currentTabTopCountries]);
    // }, 4000);
  }

  render() {
    const colSize = 24;
    const {
      total_countries_pending,
      total_countries,
      confirmed_total_pending,
      confirmed_total,
      confirmed_bd_total_pending,
      confirmed_bd_total,
      last_update_time,
      last_update_time_pending,
      total_india_pending,
      total_india,
      total_us_pending,
      total_us,
      total_spain_pending,
      total_spain,
      total_germany_pending,
      total_germany,
      total_italy_pending,
      total_italy,
      total_france_pending,
      total_france,
      total_uk_pending,
      total_uk,
    } = this.props.analyzeTotal;
    return (
      <div style={{ overflow: "hidden" }}>
        <Row gutter={[4, 8]} justify="center">
          <Col
            className="gutter-row"
            lg={colSize}
            style={{ textAlign: "center" }}
          >
            <SingleCard
              textalign="center"
              pending={last_update_time_pending}
              data={last_update_time}
              title="Last Update"
              extraLabel="GMT +6"
              renderLabel={false}
            />
            <Button className="btn-custom1" onClick={this.props.openModal}>
              More Information
            </Button>
          </Col>

          <Col className="gutter-row" lg={colSize}>
            <Tabs
              className="left-pane tab-center map-tab"
              type="card"
              tabBarStyle={{
                fontSize: "13px",
              }}
              style={{ background: "#fff" }}
              size="large"
              animated={true}
            >
              <TabPane tab="Global" key="11">
                <p
                  style={{
                    textAlign: "center",
                    background: red[8],
                    fontSize: "18px",
                    color: "#fff",
                    marginBottom: "0px",
                  }}
                >
                  {!total_countries_pending &&
                    "Countries: " + total_countries[0].Countries}
                </p>
                <SingleCard
                  pending={confirmed_total_pending}
                  data={confirmed_total}
                  title=""
                  renderLabel={true}
                />
                {/* <CardTab></CardTab> */}
              </TabPane>
              <TabPane tab="Bangladesh" key="12">
                <SingleCard
                  pending={confirmed_bd_total_pending}
                  data={confirmed_bd_total}
                  title=""
                  renderLabel={true}
                />
              </TabPane>
            </Tabs>
            <Row>
              <Col span={24}>
                <Tabs
                  type="card"
                  className="map-tab"
                  style={{ background: "#fff" }}
                  animated={true}
                >
                  <TabPane tab="USA" key="21a">
                    <SingleCard
                      pending={total_us_pending}
                      data={total_us}
                      title=""
                      renderLabel={true}
                    />
                  </TabPane>
                  <TabPane tab="Spain" key="22b">
                    <SingleCard
                      pending={total_spain_pending}
                      data={total_spain}
                      title=""
                      renderLabel={true}
                    />
                  </TabPane>
                  <TabPane tab="Germany" key="23c">
                    <SingleCard
                      pending={total_germany_pending}
                      data={total_germany}
                      title=""
                      renderLabel={true}
                    />
                  </TabPane>
                  <TabPane tab="Italy" key="24d">
                    <SingleCard
                      pending={total_italy_pending}
                      data={total_italy}
                      title=""
                      renderLabel={true}
                    />
                  </TabPane>
                  <TabPane tab="France" key="25e">
                    <SingleCard
                      pending={total_france_pending}
                      data={total_france}
                      title=""
                      renderLabel={true}
                    />
                  </TabPane>
                  <TabPane tab="UK" key="26f">
                    <SingleCard
                      pending={total_uk_pending}
                      data={total_uk}
                      title=""
                      renderLabel={true}
                    />
                  </TabPane>
                </Tabs>
              </Col>
              <Col span={24}>
                <Card
                  className="single-card"
                  headStyle={{
                    fontWeight: "500",
                    textAlign: "center",
                    background: "#cccccc",
                    color: "black",
                    minHeight: "0px",
                  }}
                  bodyStyle={{ padding: "0px" }}
                  title="Visual Exploration"
                  style={{ background: "#fafafa" }}
                >
                  <Anchor affix={false}>
                    <Link
                      target="_blank"
                      href="/pages/bubble-map"
                      title="Bubble Map"
                    />
                    <Link
                      target="_blank"
                      href="/pages/heat-map"
                      title="Heat Map"
                    />
                  </Anchor>
                </Card>
              </Col>
              {/* <Col span={24}>
                <Card
                  className="single-card"
                  headStyle={{
                    fontWeight: "500",
                    textAlign: "center",
                    background: "#cccccc",
                    color: "black",
                    minHeight: "0px",
                  }}
                  bodyStyle={{ padding: "0px" }}
                  title="Share feedback"
                  style={{ background: "#fafafa" }}
                >
                  <Anchor affix={false}>
                    <Link
                      target="_blank"
                      href="mailto:madhurza4u@yahoo.com"
                      title="madhurza4u@yahoo.com"
                    />
                    <Link
                      target="_blank"
                      href="mailto:nibras.rakib@gmail.com"
                      title="nibras.rakib@gmail.com"
                    />
                  </Anchor>
                </Card>
              </Col> */}
            </Row>
          </Col>
        </Row>
        <Modal
          title="Information"
          visible={this.props.analyzeTotal.modal_visible}
          onOk={this.props.closeModal}
          onCancel={this.props.closeModal}
        >
          <Row>
            <Col span={24}>
              <Title level={4}>Contributors</Title>
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: "15px" }}>
            <Col span={24}>
              <Text strong>
                During this challenging time, we are pleased to do our part to
                contribute to the worldwide volunteer-based public effort to
                offer COVID-19 data insights through
                http://www.globalhealthanalytics.info/. In the coming weeks, we
                will continue to improve the site and will also introduce
                country-specific trend analysis and disease modeling features...
                stay tuned.
                <br></br>
                <Text>#stayhome #staysafe</Text>
              </Text>
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: "15px" }}>
            <Col span={12}>
              <Row>
                <Avatar
                  shape="square"
                  size={128}
                  icon={<UserOutlined />}
                  src="/static/mgkibria.jpg"
                  className="avatarImg"
                />
              </Row>
              <Row>
                <Col span={24}>
                  <Text style={{ paddingLeft: "6px" }}>
                    Mohammad Golam Kibria
                  </Text>
                  <br></br>
                  <Text style={{ paddingLeft: "6px" }}>
                    <a href="mailto:madhurza4u@yahoo.com">
                      madhurza4u@yahoo.com
                    </a>{" "}
                  </Text>
                  <br></br>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/golamkibriamadhurza/"
                  >
                    <LinkedinOutlined
                      style={{
                        color: "#1c87bd",
                        paddingLeft: "6px",
                        fontSize: "28px",
                      }}
                    />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Avatar
                  shape="square"
                  size={128}
                  icon={<UserOutlined />}
                  src="/static/narakib.jpg"
                  className="avatarImg"
                />
              </Row>
              <Row>
                <Col span={24}>
                  <Text style={{ paddingLeft: "14px" }}>Nibras Ar Rakib</Text>
                  <br></br>
                  <Text style={{ paddingLeft: "14px" }}>
                    <a href="mailto:nibras.rakib@gmail.com">
                      nibras.rakib@gmail.com
                    </a>{" "}
                  </Text>
                  <br></br>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/nibrasarrakib"
                  >
                    <LinkedinOutlined
                      style={{
                        color: "#1c87bd",
                        paddingLeft: "14px",
                        fontSize: "28px",
                      }}
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Title level={4}>Disclaimer</Title>
            </Col>
          </Row>
          <Row style={{ marginBottom: "15px" }}>
            <Col span={24}>
              <Text>
                Information contained in this dashboard must NOT be considered
                for medical guidance or professional advice. The data and
                visualization are presented here are on an “AS IS” and “AS
                AVAILABLE” basis and is intended for educational purposes only.
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Title level={4}>Source</Title>
            </Col>
          </Row>
          <Row style={{ marginBottom: "15px" }}>
            <Col span={24}>
              <Text>
                Data Repository provided by The Center for Systems Science and
                Engineering (CSSE) at Johns Hopkins University, USA,
              </Text>
            </Col>
            <Col span={24}>
              <Text>Bangladesh IEDCR,</Text>
            </Col>
            <Col span={24}>
              <Text>World Population Prospects, 2019, UNFPA</Text>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Title level={4}>Tech Stack</Title>
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: "15px" }}>
            <Col span={24}>
              <Text>R, Python(Django), JavaScript(React, Redux)</Text>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Title level={4}>Recommended citation</Title>
            </Col>
          </Row>
          <Row style={{ marginBottom: "15px" }}>
            <Col span={24}>
              <Text>http://globalhealthanalytics.info</Text>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  analyzeTotal: state.analyzeDataReducers,
});

const mapDispatchToProps = {
  getTotalCountries,
  getConfirmedTotal,
  getDeathTotal,
  getRecoveredTotal,
  getConfirmedBDTotal,
  getLastUpdateTime,
  getUSTotal,
  getIndiaTotal,
  getSpainTotal,
  getGermanyTotal,
  getItalyTotal,
  getFranceTotal,
  getUKTotal,
  openModal,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalCard);
