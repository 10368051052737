import React from "react";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactGA from 'react-ga';

import "./App.css";
import './sass/style.scss';

import ContainerMain from "./component/main/ContainerMain";
import Analytics from "./component/page/Analytics/Analytics";
import {config} from '../src/config/config';

if(!config.debugApp){
  console.log("GA");
  ReactGA.initialize('UA-163877912-1');
  ReactGA.pageview(window.location.pathname + window.location.search);  
}

function App() {
  return (
    <div>
      <Router>
        <div>
          <Link to="/"></Link>
          <Link to="/analytics"></Link>
        </div>
        <Switch>
          <Route exact path="/">
            <ContainerMain />
          </Route>
          <Route exact path="/analytics">
            <Analytics />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
