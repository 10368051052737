import React, { Component } from "react";
import { Card, Skeleton, Divider } from "antd";

export default class SingleCard extends Component {
  render() {
    var label = this.props.renderLabel;
    var fontSize = "18px";
    return (
      <div>
        <Card
          className="single-card"
          headStyle={{
            fontWeight: "500",
            textAlign: this.props.textalign || "left",
            background: "rgb(31, 119, 180)",
            color: "white",
            minHeight: "0px"
          }}
          bodyStyle={{
            paddingTop: "5px",
            paddingBottom: "5px",
            background: '#fafafa'
          }}
          title={this.props.title}
        >
          {this.props.pending && <Skeleton />}
          {!this.props.pending &&
            this.props.data.map((v, i) => (
              <p
                style={{
                  textAlign: this.props.textalign || "left",
                  marginBottom: 0
                }}
                key={i}
              >
                <span
                  style={{
                    color: v["color"],
                    fontSize: "14px",
                    fontWeight: 500
                  }}
                >
                  {label && Object.keys(v)[0] + ":"}
                </span>{" "}
                <span
                  style={{
                    color: v["color"],
                    fontSize: "14px",
                    fontWeight: 500
                  }}
                >
                  {v[Object.keys(v)[0]].toLocaleString()}
                </span>
                {/* <br></br>
                {this.props.extraLabel && this.props.extraLabel} */}
              </p>
            ))}
        </Card>
      </div>
    );
  }
}
